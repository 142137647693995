[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }

.content-web {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 30px;
	font-size: 16px;
	font-family: 'Roboto Condensed';
	img {
		max-width: 100%; } }


.sm-simple {
	border: 0 none;
	background: none;
	@include box-shadow('none'); }

@media (min-width: 768px) {
	#main-menu > ul > li {
		float: none;
		display: table-cell;
		width: 1%;
		text-align: center; } }


.main-nav {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1000;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	background-color: $tertiaryColor;
	&:after {
		clear: both;
		content: "\00a0";
		display: block;
		height: 0;
		font: 0px/0 serif;
		overflow: hidden; } }

.nav-brand {
	float: left;
	margin: 0;
	a {
		display: block;
		padding: 11px 11px 11px 20px;
		color: #555;
		font-size: 20px;
		font-weight: normal;
		line-height: 17px;
		text-decoration: none;
		height: 60px;
		img {
			height: 100%;
			width: auto; } } }
@media (min-width: 768px) {
	.nav-brand {
		a {
			height: 100px; } } }

#main-menu > ul {
	clear: both;
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	display: none;
	li {
		background-color: transparentize($tertiaryColor, 0.25); }
	> li > a {
		text-transform: uppercase; } }


@media (min-width: 768px) {
	.main-nav {
		position: absolute;
		background-color: transparent; }
	#main-menu > ul {
		float: right;
		clear: none;
		display: block;
		> li > a > .sub-arrow {
			display: none; } } }


/* Mobile menu toggle button */

.main-menu-btn {
	float: right;
	margin: 5px 10px;
	position: relative;
	display: inline-block;
	width: 29px;
	height: 29px;
	text-indent: 29px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
	position: absolute;
	top: 50%;
	left: 2px;
	height: 2px;
	width: 24px;
	background: #555;
	-webkit-transition: all 0.25s;
	transition: all 0.25s; }

.main-menu-btn-icon:before {
	content: '';
	top: -7px;
	left: 0; }

.main-menu-btn-icon:after {
	content: '';
	top: 7px;
	left: 0; }

/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
	height: 0;
	background: transparent; }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg); }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg); }

/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px); }

/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
	display: none; }

#main-menu-state:checked ~ #main-menu {
	display: block; }

@media (min-width: 768px) {
	/* hide the button in desktop view */
	.main-menu-btn {
		position: absolute;
		top: -99999px; }
	/* always show the menu in desktop view */
	#main-menu-state:not(:checked) ~ #main-menu {
		display: block; } }












ul#lang {
	list-style: none;
	margin: 0;
	*zoom: 1;
	li {
		float: left;
		visibility: collapse;
		a {
			display: block;
			padding: 3px 10px;
			visibility: visible; } }
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; }
	&.flag {
		a {
			width: 2rem;
			height: 1.5rem;
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat; } }
	&.code {
		a {
			&:before {
				content: attr(data-code);
				text-transform: uppercase; } } }
	&.name {
		a {
			&:before {
				content: attr(title); } } } }



.relative {
	position: relative; }

.align-center {
	text-align: center; }
.middle {
	@include verticalAlign(); }


.bold {
	font-weight: bold; }
.uppercase {
	text-transform: uppercase; }


/* line break after element */
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

/* clear-fix */
.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

@media screen and (max-width: 1024px) {
	.cf-medium {
		*zoom: 1;
		&:before,
		&:after {
			content: ' ';
			display: table; }
		&:after {
			clear: both; } } }
@media screen and (min-width: 1025px) {
	.small-0 {
		width: 0; } }


/* proportional height */
#testProportional {
	margin: 50px auto;
	width: 300px;
	background-color: green; }

.crust {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		padding-top: 100%; }
	.core {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		img,
		.content {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto; }
		.content {
			width: 100%;
			height: 100%; } }
	&.cover .core img {
		/*width: 100%*/
		/*height: auto*/
		/*max-height: none*/
		min-width: 100%;
		min-height: 100%;
		height: auto;
		max-width: none; }
	&.ratio-2_1:before {
		padding-top: 50%; }
	&.ratio-4_3:before {
		padding-top: 75%; }
	&.ratio-16_9:before {
		padding-top: 56.25%; }
	&.ratio-9_4:before {
		padding-top: 44.44%; }
	&.ratio-5_4:before {
		padding-top: 80%; }
	&.ratio-1_2:before {
		padding-top: 200%; }
	&.ratio-4_5:before {
		padding-top: 125%; } }

/* icon before content */
a.icon-prev {
	&:hover {
		color: lighten($primaryColor, 20); } }
.icon-prev {
	position: relative;
	display: inline;
	margin-left: 2.25em;
	white-space: nowrap;
	&:before {
		position: absolute;
		margin-top: 0.15em;
		left: -1.20em;
		font-size: 1.10em;
		color: $quaternaryColor; }
	&:first-child {
		margin-left: 1.35em; } }





/* title after icon */
.tai {
	position: relative;
	white-space: nowrap;
	color: $primaryColor;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin-right: $base-font-size;
	&:after {
		content: attr(data-title);
		position: relative;
		display: inline-block;
		font-size: $base-font-size * 0.75;
		line-height: $base-line-height;
		margin-left: $base-font-size * 0.33; }
	&:before {
		line-height: $base-line-height; } }


@media screen and (max-width: 991px) {

	.tai {
		cursor: help;
		font-size: $base-font-size * 2.5;
		&:after {
			display: none; }
		&:hover:after,
		&:focus:after,
		&:active:after {
			display: block;
			position: absolute;
			top: $base-line-height * 0.5;
			left: 100%;
			z-index: 1000;
			background-color: transparentize($tertiaryColor, 0.15);
			font-size: $base-font-size;
			line-height: $base-line-height;
			padding: 1px 4px;
			@include border-radius(2px); }
		&:before {
			line-height: $base-line-height * 2.5; } }
	a.tai {
		cursor: pointer; } }


.req-sign {
	color: $errorColor;
	font-weight: bold; }
label {
	.req::after {
		@extend .req-sign;
		content: '*';
		position: absolute;
		margin-left: 0.3rem {
	    margin-right: 1.2rem; } } }


ul.evenly {
	text-align: justify;
	min-width: 500px;
	&:after {
		content: '';
		display: inline-block;
		width: 100%; }
	li {
		display: inline-block; } }

.clickable {
	cursor: zoom-in; }


/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	//border: 1px dashed transparentize($warningColor, 0.75)
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	// display: inherit
	&:hover {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }
