$primaryColor: #005eb2;
$secondaryColor: #05ca00;
$tertiaryColor: #ffffff;
$quaternaryColor: red;	//
$pentiaryColor: green;		//

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;

$border-color: #ebebeb;


$gutter: 15px;

$transition: 0.4s all;

@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700&subset=latin-ext');

$font: 'Titillium Web', sans-serif;
