/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value

@mixin css3-prefix($property, $value) {
	-webkit-#{$property}: #{$value};
	-khtml-#{$property}: #{$value};
	-moz-#{$property}: #{$value};
	-ms-#{$property}: #{$value};
	-o-#{$property}: #{$value};
	#{$property}: #{$value}; }


/// Border Radius
/// @param {Size} $radius [5px] - Radius
/// @require {mixin} css3-prefix

@mixin border-radius($radius: 5px) {
	@include css3-prefix('border-radius', $radius); }


/// Border Radius Separate
/// @param {Size} $topLeftRadius [5px] - Top Left
/// @param {Size} $topRightRadius [5px] - Top Right
/// @param {Size} $bottomLeftRadius [5px] - Bottom Left
/// @param {Size} $bottomRightRadius [5px] - Bottom Right

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
	-webkit-border-top-left-radius: $topLeftRadius;
	-webkit-border-top-right-radius: $topRightRadius;
	-webkit-border-bottom-right-radius: $bottomRightRadius;
	-webkit-border-bottom-left-radius: $bottomLeftRadius;
	-moz-border-radius-topleft: $topLeftRadius;
	-moz-border-radius-topright: $topRightRadius;
	-moz-border-radius-bottomright: $bottomRightRadius;
	-moz-border-radius-bottomleft: $bottomLeftRadius;
	border-top-left-radius: $topLeftRadius;
	border-top-right-radius: $topRightRadius;
	border-bottom-right-radius: $bottomRightRadius;
	border-bottom-left-radius: $bottomLeftRadius; }


/// Generic Transform
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform($transforms) {
	@include css3-prefix('transform', $transforms); }


/// Transform-Origin
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform-origin($params) {
	@include css3-prefix("transform-origin", $params); }


/// Transform-Style
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform-style($style: preserve-3d) {
	@include css3-prefix("transform-style", $style); }


/// Rotate
/// @param {$deg} Degree

@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg)); }


/// Scale
/// @param {$scale}

@mixin scale($scale: 1.15) {
	@include transform(scale($scale)); }


/// Translate
/// @param {$x}
/// @param {$y}

@mixin translate($x, $y) {
	@include transform(translate($x, $y)); }


/// Transition
/// @param {List} $properties - Properties
/// @require {mixin} css3-prefix

@mixin transition($properties...) {
	@if length($properties) >= 1 {
		@include css3-prefix('transition', $properties); }
	@else {
		@include css3-prefix('transition', 'all 0.25s ease-in-out 0s'); } }


/// Opacity
/// @param {Double} $opacity [0.5] - Opacity
/// @require {mixin} css3-prefix

@mixin opacity($opacity: 0.5) {
	$opacityMultiplied: $opacity * 100;
	filter: alpha(opacity = $opacityMultiplied);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
	@include prefix("opacity", $opacity); }


// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg)); }








@mixin box-shadow($shadow) {
	@include css3-prefix('box-shadow', $shadow); }


@mixin verticalAlign($position: relative, $value: 50%) {
	position: $position;
	top: $value;
	@include css3-prefix(transform, translateY(-$value)); }


@mixin horizontalAlign($position: relative, $value: 50%) {
	position: $position;
	left: $value;
	@include css3-prefix(transform, translateX(-$value)); }


@mixin absoluteAlign($position: absolute, $valueX: 50%, $valueY: 50%) {
	position: $position;
	top: $valueY;
	left: $valueX;
	@include css3-prefix(transform, translate(-$valueX, -$valueY)); }


@mixin linearGradient($top, $bottom) {
	background: $top;
	background: -moz-linear-gradient(top,  $top 0%, $bottom 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom));
	background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%);
	background: -o-linear-gradient(top,  $top 0%,$bottom 100%);
	background: -ms-linear-gradient(top,  $top 0%,$bottom 100%);
	background: linear-gradient(to bottom,  $top 0%,$bottom 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); }



@mixin aspect-ratio($arglist...) {
	$map: keywords($arglist);
	$height: map-get($map, height) or nth-value($arglist, 2);
	$width: map-get($map, width) or nth-value($arglist, 1);
	$ratio: map-get($map, ratio) or if($width and $height, $width / $height, nth-value($arglist, 1)) or 1;
	$padding: 1 / $ratio * 100%;

	//@warn $ratio
	position: relative;

	&::before, &::after {
		content: '';
		display: table;
		clear: both;
		width: 1px;
		margin-left: -1px; }

	&::before {
		float: left;
		padding-bottom: $padding; }

	> .aspect {
		position: absolute;
		max-width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		img {
			z-index: -1;
			@include absoluteAlign;
			@if($ratio > 1) {
				&:not(.cover) {
					height: 100%;
					&:not(.contain) {
						height: auto;
						max-height: 100%; } }
				&.cover {
					height: auto;
					max-height: none;
					width: 100%; } }
			@else {
				&:not(.cover) {
					width: 100%;
					&:not(.contain) {
						width: auto;
						max-width: 100%; } }
				&.cover {
					width: auto;
					max-width: none;
					height: 100%; } } } } }






@mixin aspect-ratio-1($arglist...) {
	$map: keywords($arglist);
	$height: map-get($map, height) or nth-value($arglist, 2);
	$width: map-get($map, width) or nth-value($arglist, 1);
	$ratio: map-get($map, ratio) or if($width and $height, $width / $height, nth-value($arglist, 1)) or 1;
	$padding: 1 / $ratio * 100%;

	&::before, &::after {
		content: '';
		display: table;
		clear: both;
		width: 1px;
		margin-left: -1px; }

	&::before {
		float: left;
		padding-bottom: $padding; } }

// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-value($list, $index) {
	@return if(length($list) >= $index, nth($list, $index), null); }






