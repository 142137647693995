.translate-button {
	display: inline-block;
	cursor: pointer;
	-webkit-appearance: none;
	transition: background-color .25s ease-out,color .25s ease-out;
	border: 1px solid transparent;
	border-radius: 5px;
	padding: .85em 1em;
	margin: 1rem 0 1rem;
	font-size: .9rem;
	background-color: #ed6c08;
	color: #fefefe;
	text-transform: uppercase; }

.translate-button:hover {
	background-color: #c95c07;
	color: #fefefe; }

/* BASIC */

html, body, h1, h2, h3, h4, h5, h6, a, p, span, input, textarea, select, option, div {
	font-family: $font; }
a, button {
	&:hover, &:active, &:focus {
		text-decoration: none; } }
.border {
	border: 1px solid $border-color; }
.nopad {
	padding: 0;
	margin: 0; }
.simpleflex {
	display: flex;
	flex-wrap: wrap; }
.simpleflex-item {
	align-self: center; }
.img-center {
	margin: 0 auto;
	display: block; }
.text-r {
	width: 100%;
	text-align: right; }

////-------------------------
////------- PERLON NEW STYLES
////-------------------------

$primaryColor: #ec1d23;		//cervena
$primaryColorHover: #fc1d23;
$secondaryColor: #181818;	//tmavo cierna
$secondaryColorLight: #313131;
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//
$darkblack: #5f5f5f; // tmava cierna

$gray: #c0c0c0;
$grayLight: #e6ebeb;
$graydark: #e8e8e8;
$grayText: #9b9b9b;


$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Titillium Web', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;

$light: #ffffff; // biela
$whiteLight: #fbfbfb; //svetla biela
$whiteDark: #f2f2f2; //tmava biela

$black: #000000;
$atracite: #293133;


@media(min-width: 768px) {
    .reset-padding-left {
        padding-left: 0; }
    .reset-padding-right {
        padding-right: 0; }
    .reset-padding-middle {
        padding-left: 0;
        padding-right: 0; } }

@media(max-width: 768px) {
    .frst {
        display: none !important; }
    .menuoffset {
        margin-top: 0 !important; }
    .showw {
        display: none; } }


.img-scroll {
    position: fixed;
    left: 0%;
    top: 30%;
    z-index: 1100; }

body,
html {
    font-family: 'Titillium Web', sans-serif; }

a:hover {
    text-decoration: none !important; }

.dokument {
    width: 50%;
    height: 50px;
    background-color: #181818;
    color: white;
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    padding-left: 12px;
    margin-top: -8px;
    a {
        color: white;
        text-decoration: none;
        height: 100%;
        width: 100%; }
    @media(max-width: 1024px) {
        width: 80%; }
    @media(max-width: 415px) {
        font-size: 0.8em;
        padding-top: 14px;
        padding-left: 8px;
        margin-top: -8px; }
    @media(max-width: 340px) {
        font-size: 0.75em; }
    &:after {
        font-family: FontAwesome;
        content: "\f054";
        color: #f2f2f2;
        float: right;
        margin-right: 1em; }
    &:hover {
        color: #000047;
        background-color: #e1e1e1;
        a {
            color: #000047;
            text-decoration: none; }
        &:after {
            color: #000047; } } }

.dokument-nadpis {
    font-weight: bold;
    text-transform: uppercase;
    width: 50%;
    height: 50px;
    color: #ec1d23;
    font-size: 1.5em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    @media(max-width: 768px) {
        display: table;
        text-align: center; } }

.section-set-pad {
    padding: 30px; }

.main-heading {
    margin: 0;
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 42px;
    font-weight: bold;
    color: $primaryColor; }

.secundary-heading {
    margin: 0;
    padding-top: 15px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 22px;
    font-weight: 300;
    color: $secondaryColor; }

.nopad {
    padding-top: 0px !important; }

.btn {
    padding: 20px 30px;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
    border-width: 0px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-image: none;
    position: relative;
    &.btn-flex {
        width: 100%; }
    .fa {
        float: right;
        font-weight: bold;
        font-size: 20px;
        position: absolute;
        top: 21px;
        right: 17px; } }

.btn-primary {
    background-color: $primaryColor;
    @include transition();
    &:hover,
    &:focus {
        background-color: $primaryColorHover;
        background-image: none; } }

.btn-secundary {
    border: 2px solid $black;
    background-color: $light;
    color: $black;
    @include transition();
    &:hover,
    &:focus {
        background-color: $black;
        background-image: none;
        color: $light; } }

@media (max-width: 991px) {
    .navbar-header {
        float: none; }

    .navbar-toggle {
        display: block;
        margin-top: 20px; }

    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
        border-top: 0px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
        background-color: $light;
        margin-top: 10px;
        max-height: auto;
        overflow-x: auto; }


    // .navbar-collapse.collapse
    //     display: none !important

    .navbar-nav {
        float: none !important;
        margin: 7.5px 0px;
        background-color: $light; }

    .navbar-default .navbar-nav {}


    .navbar-nav>li {
        float: none; }

    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px; }

    .navbar-text {
        float: none;
        margin: 15px 0; }

    .navbar-collapse.collapse.in {
        display: block !important;
        box-shadow: 0px 5px 10px -5px rgba(0,0,0,.45); }

    .collapsing {
        overflow: hidden !important; } }



.bar-contact {
    height: 40px;
    background-color: $secondaryColor;
    .contact-link {
        padding-top: 7px;
        padding-right: 25px;
        a {
            margin-right: 25px;
            font-size: 16px;
            color: $light;
            @include transition();
            .fa {
                margin-right: 5px;
                color: $darkblack; }
            &:last-child {
                margin-right: 0; }
            &:hover,
            &:focus {
                text-decoration: none;
                color: $primaryColor; } }
        @media(max-width: 480px) {
            padding-right: 15px;
            a {
                margin-right: 10px;
                font-size: 14px; } } } }

.navbar-fixed-top {
    margin-top: 40px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    &.navbar-scroll {
        margin-top: 0px; } }

.menu_level_1 {
    /*display: none*/ }

.navbar-nav li:hover .menu_level_1 {
    display: block;
    margin-top: 34px; }
.menu_level_1:hover {
    display: block; }
.menu_level_1, .dropdown-menu {
    display: none;
    /*position: absolute*/
    position: relative;
    background-color: #fff;
    line-height: 2em;
    /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)*/
    min-width: 100%;
    z-index: 9999;
    border-left: 1px solid #E6EBEB;
    border-right: 1px solid #E6EBEB;
    color: #777;
    @include transition();
    padding-left: 0em; }
.menu_level_1, .dropdown-menu {
    li {
        list-style: none;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 14px;
        color: #000;
        padding-top: 10px;
        text-align: left;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid #E6EBEB;
        background-color: #fff;
        &:hover {
            background-color: $graydark !important; }
        > a {
            padding-bottom: 0px !important;
            width: 100%;
            height: 100%;
            display: block;
            &:hover {
                text-decoration: none;
                background-color: transparent !important; }
            > span {
                height: 0px !important; } } } }
.menu_level_1 a, .dropdown-menu a {
    color: #777; }

.navbar-default {
    height: 95px;
    background-image: none;
    background-color: #fff;
    box-shadow: 0 0 0 transparent;
    border-bottom: 1px solid $grayLight;
    border-top: 1px solid $grayLight;
    .navbar-brand {
        > img {
            height: 55px;
            margin-top: 5px;
            margin-left: 30px; }
        @media(max-width: 1390px) {
            > img {
                height: 45px;
                margin-top: 0; } }
        @media(max-width: 1180px) {
            > img {
                height: 35px;
                margin-top: 5px; } }
        @media(max-width: 991px) {
            > img {
                margin-left: 15px; } }
        @media(max-width: 767px) {
            > img {
                margin-left: 0px; } }
        @media(max-width: 480px) {
            > img {
                height: 32px; } } }
    .navbar-toggle {
        border-color: transparent;
        .icon-bar {
            background-color: $primaryColor;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: transform .4s ease;
            transition: transform .4s ease; }
        &:hover {
            background-color: transparent; } }
    .iconhover {
        background-color: transparent;
        .first {
            position: relative;
            top: 6px;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
        .third {
            position: relative;
            top: -6px;
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
        .second {
            background-color: transparent; } }

    .navbar-nav {
        > li {
            > a {
                padding: 34px 20px 45px 20px;
                text-transform: uppercase;
                font-size: 18px;
                border-right: 1px solid $grayLight;
                color: #000; }
            &:first-child {
                > a {
                    border-left: 1px solid $grayLight; } } }
        @media(min-width: 1750px) {
            margin-left: 100px; }
        @media(min-width: 1700px) {
            margin-left: 50px; }

        @media(max-width: 1600px) {
            > li {
                > a {
                    padding: 30px 15px 45px 15px; } } }
        @media(max-width: 1540px) {
            > li {
                > a {
                    padding: 25px 15px 45px 15px; } } }

        @media(max-width: 1334px) {
            > li {
                > a {
                    font-size: 16px; } } }

        @media(max-width: 1260px) {
            > li {
                > a {
                    font-size: 14px; } } }

        @media(max-width: 1110px) {
            > li {
                > a {
                    padding: 25px 10px 45px 10px; } } }
        @media(max-width: 1000px) {
            > li {
                > a {
                    font-size: 13.5px; } } }

        @media(max-width: 991px) {
            > li {
                border-bottom: 1px solid $grayLight;
                > a {
                    padding: 10px;
                    border-width: 0px; }
                &:first-child {
                    border-top: 1px solid $grayLight;
                    > a {
                        border-width: 0px; } } } }

        &.navbar-right {
            margin-left: 0px; }
        .languages-menu,
        .next-project {
            width: 100px;
            background-color: $graydark;
            > a {
                padding: 25px 15px 10px;
                border-right: 0px solid transparent;
                @include transition();
                text-align: center;
                .fa {
                    display: block;
                    margin: 0 auto;
                    margin-top: 15px; }
                &:hover,
                &:focus {
                    background-color: $graydark; }
                &:after {
                    display: none; } }
            @media(max-width: 1390px) {
                > a {
                    padding: 23px 15px 10px 15px;
                    .fa {
                        margin-top: 2px; } } }
            @media(max-width: 1320px) {
                > a {
                    padding: 25px 15px 10px 15px; } }
            @media(max-width: 1250px) {
                > a {
                    padding: 25px 15px 12px 12px; } }

            @media(max-width: 1110px) {
                > a {
                    padding: 25px 10px 12px 10px; } }
            @media(max-width: 991px) {
                > a {
                    padding: 12px 10px 12px 10px;
                    text-align: left;
                    .fa {
                        float: right;
                        display: inline-block; } } } }

        .languages-menu {
            .dropdown-menu {
                background-color: #fff;
                text-align: center; } }
        .next-project {
            width: auto;
            background-color: $light;
            > a {
                padding-bottom: 9px;
                margin-right: 0;
                text-align: center;
                border-bottom: 1px solid $grayLight; }
            .menu_level_1 {
                margin-top: 0; }
            @media(max-width: 1250px) {
                > a {
                    padding-bottom: 12px; } } }

        ul#lang li {
            float: none;
            width: 100%;
            padding: 0;
            a {
                text-align: center;
                padding: 10px; }
            &:not(:last-child) a {
                border-bottom: 1px solid $grayLight !important; } }

        &.navbar-main {
            li {
                a {
                    padding-bottom: 20px;
                    text-align: left;
                    @include transition();
                    &:hover,
                    &:focus {
                        background-color: $graydark; }
                    span {
                        height: 20px;
                        margin-top: 4px;
                        display: block;
                        text-align: left;
                        text-transform: lowercase;
                        font-size: 12px;
                        color: $grayText; } } }

            @media(max-width: 1540px) {
                li {
                    a {
                        padding-bottom: 48px;
                        span {
                            display: none; } } } }
            @media(max-width: 1390px) {
                li {
                    a {
                        padding-bottom: 29px; } } }
            @media(max-width: 991px) {
                li {
                    a {
                        padding-bottom: 10px;
                        span {
                            height: 0px;
                            display: block; } } } } } }
    @media(max-width: 1390px) {
        height: 75px; }
    @media(max-width: 1110px) {
        .navbar-nav {
            .languages-menu {
                width: 55px; } } }
    @media(max-width: 991px) {
        .navbar-nav {
            margin-bottom: 0px;
            .languages-menu {
                width: 100%;
                > a {
                    margin-right: 0px; } }
            &.sub-navbar {
                position: relative;
                top: -1px;
                margin-top: 0px; } } } }

.main-banner {
    height: 439px;
    margin-top: 90px;
    background: url('../images/layout/banner.png') center center no-repeat;
    background-size: cover;
    @media(max-width: 1199px) {
        background: url('../images/layout/banner-1199.jpg') center center no-repeat; }
    @media(max-width: 991px) {
        background: url('../images/layout/banner-992.jpg') center center no-repeat; }
    @media(max-width: 767px) {
        height: 350px;
        background: url('../images/layout/banner-768.jpg') center center no-repeat; }

    .text-banner {
        display: block;
        padding-top: 100px;
        text-align: center;
        h1,
        h2 {
            margin: 0;
            text-transform: uppercase;
            font-weight: 300;
            color: $primaryColor; }
        h1 {
            font-weight: bold;
            font-size: 50px; }
        h2 {
            font-size: 27px; }
        @media(max-width: 767px) {
            padding-top: 45px;
            h1 {
                font-size: 38px; }
            h2 {
                font-size: 20px; } }

        @media(max-width: 480px) {
            h1 {
                font-size: 38px; }
            h2 {
                font-size: 20px; } } }

    .benefits-slider {
        margin-top: 55px;
        padding-left: 0;
        li {
            display: inline-block;
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
            &:after {
                position: relative;
                top: 3px;
                margin-left: 25px;
                margin-right: 25px;
                content: ' ';
                height: 22px;
                width: 14px;
                display: inline-block;
                background: url('../images/layout/list-style.png') center center no-repeat; }
            &:last-child {
                &:after {
                    display: none; } } }
        @media(max-width: 1125px) {
            li {
                font-size: 19px;
                &:after {
                    top: 5px; } } }
        @media(max-width: 991px) {
            li {
                font-size: 18px;
                &:after {
                    top: 5px; } } }
        @media(max-width: 950px) {
            li {
                font-size: 16px;
                &:after {
                    top: 5px; } } }
        @media(max-width: 850px) {
            li {
                font-size: 14px;
                &:after {
                    top: 5px; } } }
        @media(max-width: 767px) {
            margin-top: 25px;
            li {
                display: block;
                &:after {
                    display: none; } } } }

    @media(max-width: 1390px) {
        margin-top: 71px; } }

.categories {
    position: relative;
    top: -60px;
    padding: 15px;
    background-color: $light;
    border-radius: 6px;
    @media(max-width: 480px) {
        .col-xs-8 {
            width: 100%; }
        .col-xs-offset-2 {
            margin-left: 0; } } }

.item-category {
    padding: 12px;
    border: 1px solid transparent;
    border-radius: 6px;
    @include transition();
    .body-category {
        .main-img {
            width: 100%;
            height: 210px;
            max-height: 210px; }
        > h3 {
            margin-top: 30px;
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 25px;
            text-transform: uppercase;
            color: $secondaryColor; }
        @media(max-width: 1199px) {
            .main-img {
                height: 170px; }
            > h3 {
                font-size: 18px; } }
        @media(max-width: 991px) {
            .main-img {
                height: 130px; }
            > h3 {
                font-size: 14px; } }
        @media(max-width: 768px) {
            .main-img {
                height: 210px; }
            > h3 {
                font-size: 18px; } } }
    .list-categories {
        overflow: hidden;
        height: 0px;
        @include transition();
        .btn {
            margin-top: 15px; }
        ul {
            margin-top: 15px;
            padding-left: 0;
            padding-bottom: 0;
            li {
                a {
                    display: block;
                    margin-bottom: 12px;
                    list-style: none;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 300;
                    color: $secondaryColorLight;
                    &:hover,
                    &:focus {
                        color: $primaryColor; }
                    @media(max-width: 1199px) {
                        font-size: 18px; }
                    @media(max-width: 991px) {
                        font-size: 14px; }
                    @media(max-width: 768px) {
                        font-size: 18px; } } } } }
    &:hover,
    &:focus {
        border: 1px solid $grayLight;
        .list-categories {
            height: auto; } }
    &.item-services {
        .list-categories {
            height: auto;
            ul {
                margin-bottom: 30px;
                height: 153px; }
            .btn {
                width: 220px; }
            @media(max-width: 991px) {
                .btn {
                    width: 100%; } } }
        &:hover,
        &:focus {
            border: 1px solid transparent;
            .list-categories {
                height: auto; } } } }


.idustries {
    padding-top: 20px;
    padding-bottom: 30px;
    background: url('../images/layout/industries-cover.png') center center no-repeat;
    background-size: cover;
    @media(max-width: 1199px) {
        background: url('../images/layout/industries-cover-1199.jpg') center center no-repeat;
        background-size: cover; }
    @media(max-width: 991px) {
        background: url('../images/layout/industries-cover-992.jpg') center center no-repeat;
        background-size: cover; }
    @media(max-width: 767px) {
        background: url('../images/layout/industries-cover-768.jpg') center center no-repeat;
        background-size: cover; }

    background-size: cover;
    .section-set-pad {
        padding-bottom: 15px; }
    .item-industries {
        position: relative;
        margin-bottom: 15px;
        img {
            width: 100%; }
        .btn {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            margin: 0 40px;
            z-index: 10; }
        &:after {
            position: absolute;
            bottom: 0;
            content: ' ';
            height: 50px;
            width: 100%;
            display: block;
            background-color: $whiteLight;
            opacity: .8;
            z-index: 0; }
        @media(max-width: 1199px) {
            .btn {
                margin: 0 15px; } }
        @media(max-width: 991px) {
            .btn {
                font-size: 12px; } }
        @media(max-width: 768px) {
            .btn {
                font-size: initial; } } }
    @media(max-width: 480px) {
        .col-xs-8 {
            width: 100%; }
        .col-xs-offset-2 {
            margin-left: 0; } } }

.services {
    z-index: 10;
    position: relative;
    padding: 20px;
    padding-bottom: 0;
    .section-set-pad {
        padding-top: 30px;
        padding-bottom: 0px; }
    .secundary-heading {
        padding-top: 20px; }
    .item-services {
        margin-bottom: 15px;
        padding: 0;
        .main-img {
            width: 100%; } }
    @media(max-width: 480px) {
        .col-xs-8 {
            width: 100%; }
        .col-xs-offset-2 {
            margin-left: 0; } } }

.img-separator-services {
    z-index: 1;
    position: relative;
    top: -45px;
    height: 110px;
    margin-bottom: -45px;
    background: url('../images/layout/industries-cover-narrow-1199.jpg') center center no-repeat;
    background-size: cover; }

.quality {
    padding-top: 50px;
    .main-heading {
        padding: 0; }
    .edit-margin {
        margin-bottom: 30px; }
    .item-quality {
        margin-top: 30px;
        margin-bottom: 30px;
        img {
            height: 150px;
            width: 150px; }
        .secundary-heading {
            margin-top: 5px;
            font-size: 18px; } }
    @media(min-width: 992px) and (max-width: 1199px) {
        .item-quality {
            height: 275px; } } }




.about-company {
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: $whiteDark;
    .main-heading {
        padding-top: 40px; }
    .heading-about {
        margin-bottom: 40px;
        font-size: 32px;
        text-transform: uppercase; }
    .adventiges-company {
        margin-top: 60px; }
    .content-about {
        font-size: 16px; }
    .company-adventiges {
        margin-bottom: 3px;
        .media-left {
            padding: 5px 45px;
            font-size: 68px;
            font-weight: bold;
            background-color: $primaryColor;
            color: #f49395; }
        .media-body {
            padding: 20px;
            background-color: $atracite;
            color: #fff;
            .media-heading {
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                text-transform: uppercase;
                color: #fff; } } } }
.company-value {
    // .media-left
    //     width: 10%
    .media-object {
        margin-right: 5px;
        height: 50px;
        width: 50px; }
    .media-heading {
        margin-bottom: 3px;
        font-weight: bold;
        font-size: 20px; } }

.satisfaction {
    padding-top: 60px;
    padding-bottom: 60px;
    .main-heading {
        margin-bottom: 60px; } }

.body-contact {
    padding: 20px 30px;
    position: relative;
    font-size: 16px;
    color: $darkblack;
    ul {
        padding-left: 0px;
        li {
            list-style: none;
            h4 {
                margin-top: 0px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16px; } } } }
.header-contact {
    padding: 15px;
    background-color: $primaryColor;
    .btn {
        min-width: 200px;
        margin: 0 5px; }

    .btn {
        display: inline-block;
        padding: 20px;
        font-size: 25px;
        line-height: 25px;
        font-weight: bold;
        border-radius: 0;
        border: 2px solid #fff;
        text-transform: uppercase;
        text-align: center; }

    .btn-ghost {
        background-color: $primaryColor;
        color: #fff;
        &:hover,
        &.active,
        &:focus {
            box-shadow: 0 0 0 0;
            border-color: #fff;
            background-color: #fff;
            color: $primaryColor; } } }

.contact-company {
    padding-top: 15px;
    padding-bottom: 30px; }

.contact-info {
    background: url('../images/layout/banner.png') center center no-repeat;
    background-size: cover;
    @media(max-width: 1199px) {
        background: url('../images/layout/banner-1199.jpg') center center no-repeat; }
    @media(max-width: 991px) {
        background: url('../images/layout/banner-992.jpg') center center no-repeat;
        background-size: auto 100%; }
    @media(max-width: 768px) {
        background: url('../images/layout/banner-768.jpg') center center no-repeat;
        background-size: auto 100%; }
    position: relative; }

.contact-form {
    display: none;
    position: relative;
    z-index: 10;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    .message-warning {
        color: $secondaryColor; }
    .heading-form {
        position: relative;
        top: -15px;
        display: inline-block;
        padding: 15px 20px;
        margin: 0px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #4e4e50;
        color: #fff; }
    input,
    textarea {
        //padding: 20px 15px
        text-transform: uppercase;
        font-size: 10px;
        border-radius: 0px; }
    textarea {
        height: 140px; }
    .form-group {
        margin-bottom: 8px; }
    .btn-tercialy {
        width: 160px;
        margin-top: 7px;
        margin-bottom: 15px; } }


.main-footer {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $whiteLight;
    color: $darkblack;
    a {
        color: $primaryColor;
        &:hover,
        &:focus {
            color: $primaryColor; } } }

.tabs-rk-footer {
    .nav-tabs {
        margin-bottom: 30px;
        text-align: center;
        border-bottom: 0px solid transparent;
        li {
            margin-bottom: 10px;
            display: inline-block;
            float: none;
            margin: 0 5px;
            @media(max-width: 715px) {
                margin-top: 0.5em; }
            a {
                padding: 5px 14px;
                font-size: 14px;
                text-transform: uppercase;
                border: 2px solid $primaryColor;
                border-radius: 0px;
                font-weight: bold;
                color: $primaryColor;
                transition: all .4s ease;
                &:hover,
                &:focus {
                    background-color: $primaryColor;
                    color: $light; } } } }


    .nav-tabs>li>a.active.show,
    .nav-tabs>li>a.active.show:focus,
    .nav-tabs>li>a.active.show:hover {
        background-color: $primaryColor;
        border: 2px solid $primaryColor;
        color: $light; } }

.references {
    // margin-top: 95px
    .reference-item {
        @media(max-width: 991px) {
            margin: 30px 0;
            .pull-right {
                float: none !important; } }
        .item-head {
            position: relative;
            height: 300px;
            text-align: center;
            background-color: $light;
            .triangle {
                z-index: 10;
                position: absolute;
                height: 40px;
                width: 18px;
                top: 0;
                bottom: 0;
                margin-top: auto;
                margin-bottom: auto; }
            .triangle-left {
                right: -18px; }
            .triangle-right {
                left: -18px; }
            @media(max-width: 991px) {
                .triangle-left,
                .triangle-right {
                    right: 0;
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: -29px;
                    transform: rotate(-90deg);
                    bottom: auto; }
                .triangle-right {
                    transform: rotate(90deg); } }

            .center-help {
                display: inline-block;
                height: 100%;
                vertical-align: middle; }
            .body-reference {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%; }
            .heading-reference {
                display: inline-block;
                margin: 0 25px;
                vertical-align: middle;
                span {
                    display: table-caption;
                    font-size: 25px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: right; } }
            .btn {
                width: 220px;
                margin: 0 25px; }
            &.item-head-right {
                span {
                    text-align: left; } }
            @media(max-width: 991px) {
                height: 200px;
                .btn {
                    float: right; }
                .heading-reference {
                    margin: 16px 25px;
                    span {
                        display: inline-block; } } }
            @media(max-width: 767px) {
                .body-reference {
                    margin-top: 30px;
                    .heading-reference {
                        display: block; } }
                .center-help {
                    display: none; } } }
        .body-img {
            overflow: hidden;
            height: 300px;
            width: 100%;
            .img-cover {
                height: 300px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover; } } }
    .cover-color {
        background-color: #ececec;
        &.set-cover {
            background-color: #f2f2f2; } } }
.menuoffset {
    position: absolute;
    margin-top: 66.5px; }
.frst {
    background-color: $black;
    width: 285px;
    margin-top: -66.5px;
    color: white;
    height: 66.5px;
    display: block;
    margin-left: 0em;
    border-bottom: 1px solid #383838;
    li {
        button {
            float: right;
            right: 6px;
            top: 5px;
            color: #fff;

            &:hover {
                color: white; }
            &:active {}
            &:focus {
                color: white; } } } }


.left-menu {
    width: 285px;
    z-index: 999;
    position: absolute;
    left: 0;
    padding-left: 0;
    li {
        border-bottom: 1px solid #383838;
        &:last-child {
            border-bottom: 0px solid transparent; }
        a {
            padding: 20px 30px;
            padding-right: 40px;
            display: block;
            font-size: 17px;
            font-weight: 600;
            list-style: none;
            text-transform: inherit;
            background-color: $black;
            color: $light;
            &:after {
                float: right;
                position: relative;
                right: -16px;
                top: 10px;
                content: ' ';
                display: inline-block;
                width: 16px;
                height: 10px;
                background: url('../images/layout/arrow-menu-bottom.png') center center no-repeat;
                -webkit-transition: all .4s ease;
                transition: all .4s ease; }
            &:hover,
            &:focus {
                text-decoration: none;
                background-color: #252525; }
            &.active {
                text-decoration: none;
                background-color: #252525;
                &:after {
                    -webkit-transform: rotate3d(1,0,0,180deg);
                    transform: rotate3d(1,0,0,180deg); } } } }
    @media(max-width: 767px) {
        display: none;
        width: 100%;
        li {
            a {
                padding-right: 45px; } } } }

.frstshow {
    transform: translateX(calc(-100% - -60px)); }

.hide2 {
    margin-left: -285px !important; }

.showw {
    margin-left: 0; }



.sub-left-menu {
    display: none;
    padding-left: 0;
    li {
        list-style: none;
        a {
            padding: 14px 30px;
            font-weight: 300;
            background-color: #383838;
            &:after {
                display: none; }
            &:hover,
            &:focus {
                background-color: #464646; } } } }


.main-bar {
    padding-top: 60px;
    padding-bottom: 60px;
    > h1 {
        margin: 0;
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        color: $primaryColor;
        margin-top: 1.2em;
        text-align: center; }
    .sub-heading {
        margin-top: 10px;
        letter-spacing: 3px;
        font-size: 20px;
        font-weight: 300;
        color: $secondaryColor; } }

.sub-menu {
    padding-left: 30px;
    height: 60px;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    background-color: $black;
    > h4 {
        margin-top: 15px;
        display: inline-block;
        font-weight: bold;
        font-size: 22px;
        color: $light; }

    button {
        margin-top: 8px;
        padding: 10px;
        margin-right: 15px;
        .icon-bar {
            margin-top: 4px;
            display: block;
            width: 22px;
            height: 2px;
            border-radius: 1px;
            background-color: $light;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: transform .4s ease;
            transition: transform .4s ease; }
        /*&:focus
            background-color: transparent
            .first
                position: relative
                top: 6px
                -ms-transform: rotate(45deg)
                -webkit-transform: rotate(45deg)
                transform: rotate(45deg)
            .third
                position: relative
                top: -6px
                -ms-transform: rotate(-45deg)
                -webkit-transform: rotate(-45deg)
                transform: rotate(-45deg)
            .second
                background-color: transparent*/ } }


.menu-bottom {
    margin-bottom: 100px;
    height: 150px;
    padding-left: 0;
    background-color: $secondaryColor;
    li {
        display: inline-block;
        float: left;
        width: 50%;
        list-style: none;
        border-right: 1px solid #7f7f7f;
        &:last-child {
            border-right: 0px solid transparent; }
        a {
            padding: 60px;
            display: block;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            color: #7f7f7f;
            &:after {
                float: right;
                position: relative;
                right: 15px;
                top: 10px;
                content: ' ';
                display: inline-block;
                width: 10px;
                height: 16px;
                background: url('../images/layout/menu-right-triangle.png') center center no-repeat; }

            &:hover,
            &:focus {
                text-decoration: none;
                background-color: #464646; } }
        &.active {
            display: none; } }
    @media(max-width: 991px) {
        li {
            width: 100%;
            a {
                background-color: $secondaryColor; } } } }

.detail-blog {
    // margin-top: 95px
    padding-bottom: 50px; }

.detail-page {
    font-size: 16px; }

.btn-back {
    margin-top: 30px;
    margin-left: 15px; }

.lightSlider {
    margin-top: 10px;
    li {
        img {
            width: 100%;
            height: 100px; } } }

.lg-backdrop.in {
    opacity: .6; }

.center {
	margin: 0 auto;
	display: block;
	text-align: center; }

.upper-footer {
	background-color: $graydark;
	padding-bottom: 20px;
	padding-top: 20px;
	color: #9b9b9b;
	font-size: 14px; }


////--------------------------
////---- 2019 ----------------
.navbar {
	padding: 0; }
