@CHARSET 'UTF-8' {}

@import 'variables';
@import 'functions';
@import 'mixins';

@import 'master';

// placeholder clear-fix
%clearfix {
	*zoom: 1;
	&:before, &:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

// clear-fix
.cf {
	@extend %clearfix; }

// line break after element
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

//	header
#topline {
	background-color: #000000;
	color: yellow;
	a {
		color: #cdcdcd;
		&:hover {
			color: #ffffff; } }
	ul#lang {
		display: inline-block;
		font-size: 0.8 * $base-font-size; } }



ul.nav {
	list-style: none;
	li {
		position: relative;
		white-space: nowrap;
		> ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: none; }
		&:hover {
			> ul {
				display: block;
				position: absolute;
				left: 0;
				background-color: #555;
				> li {
					white-space: nowrap;
					> ul {
						left: 100%;
						top: 0;
						background-color: #444; } } } } } }



.test111 {
	color: red;
	font-size: 50px;
	display: inline-block;
	width: 200px;
	&:hover {
		@include scale(1.5); } }



//.ratio
//	border: 1px solid red
//	+aspect-ratio(3,4)
//	img
//		border: 3px solid blue





// cookies style
.cookieinfo {
	background-color: rgba(0,0,0, 0.8) !important;
	color: #ffffff !important;
	#cookie_more_info {
		color: #ffffff !important;
		text-decoration: underline !important; }
	.cookieinfo-close {
		background-color: transparent !important;
		border: 2px solid #ffffff !important;
		color: #ffffff !important;
		&:hover {
			background-color: #ffffff !important;
			color: #000000 !important; } } }




// footer
body > footer {
	background-color: #a8a8a8;
	.copyright {
		background-color: #787878;
		text-align: center;
		margin-top: $gutter; } }




//@import 'footer'
@import 'sixadmin';


@import 'modules/default_sixnet_eshop';
@import 'modules/default_sixnet_article';
@import 'modules/contact';
@import 'modules/home';
@import 'modules/article';
@import 'default_javascript';

ul.slickSlider {
	padding: 0;
	li {
		position: relative;
		width: 100%;
		height: 33vh;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover; } }

.sixnetFooter {
	max-height: 32px; }
